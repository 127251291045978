.cls-1 {
  fill: rgb(0, 0, 0);
}
.cls-2 {
  fill: rgb(44, 35, 35);
}
.cls-3 {
  fill: rgb(44, 35, 35);
}

#stripe {
  animation: stripe-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg)
  }
  75% {
    transform: translateX(10px)
  }
}
.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s
}

.circle.c2 {
  animation-duration: 3s
}

.circle.c3 {
  animation-duration: 1s
}

.circle.c4 {
  animation-duration: 1s
}

.circle.c5 {
  animation-duration: 2s
}

.circle.c6 {
  animation-duration: 3s
}

@keyframes circle-anim {
  50% {
    transform: scale(.2) rotateX(360deg) rotateY(360deg)
  }
}

.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(.98)
  }
}

.header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.header a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header a:hover {
  background-color: #f4f4f4;
}

.munyori-heading h5{width:100%;text-align:center;border-bottom:1px solid #000000;line-height:.1em;margin:10px 0 20px}
.munyori-heading h5 span{background:#fff;padding:0 10px}

.diagonal-split-background{
  background-color: #f44336;
  background-image: -webkit-linear-gradient(30deg, #ff9800 50%, #f44336 50%);
}

.chat-box {
  height: 75VH;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #999 #eee;
}

.chat-box::-webkit-scrollbar {
  width: 8px;
}

.chat-box::-webkit-scrollbar-track {
  background: #eee;
}

.chat-box::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  border: 3px solid #eee;
}

.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-messages li {
padding: 10px;
}

.bounce {
  position: relative;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    top: 0;
  }
  50% {
    top: -20px;
  }
}

.container-auth {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.container-auth input[type=text], input[type=password], input[type=email], input[type=date]{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.container-auth select{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.container-auth button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.container-auth button:hover {
  background-color: #45a049;
}

img{
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.input-box {
  border-radius: 20px;
  padding: 10px;
  background-color: #eee;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.settings-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.settings-button i {
  font-size: 1.9rem;
  color: orange;
}

.settings-button i :hover {
  background-color: green;
}

.input-box input[type="text"] {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
}

.submit-button {
  background-color: red;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
}

.submit-button:hover{
  background-color: #4CAF50;
}
.settings-modal {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.settings-modal label {
  display: block;
  margin-bottom: 10px;
}

.settings-modal button {
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
